import React from 'react';

import SsrLogger from '@services/logger/ssrLogger';

import { DevLoginPage } from '@modules/Auth/DevLogin/DevLogin';
import { Paths } from '@utils/paths/paths';
import { PathsConfig } from '@utils/paths/paths-config';

import { getGlobalStories, getStory } from '@services/storyblok';
import { useStoryblok } from '@services/storyblok/bridge';

const pageId = Paths.DevLogin;
const ssrLogger = new SsrLogger(`bm:pages:storyblok:${pageId}`);

export const DevLogin = ({ story, preview, pageContext }) => {
  const finalStory = useStoryblok(story, preview);

  return <DevLoginPage story={finalStory} pageContext={pageContext} />;
};

export default DevLogin;

export const getServerSideProps = async context => {
  try {
    const { preview = false } = context;
    const storyblokSlug = PathsConfig[pageId].storyblokSlug;
    const { data } = await getStory({
      slug: storyblokSlug,
    });
    const globalStories = await getGlobalStories({ preview });

    return {
      props: {
        story: data ? data.story : false,
        preview,
        pageContext: {
          globalStories,
          currentPageId: pageId,
        },
      },
    };
  } catch (error) {
    ssrLogger.error(error);

    return {
      notFound: true,
    };
  }
};
