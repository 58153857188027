import React from 'react';

import BoschHeaderLogo from '@assets/icons/new/logo/boschLogo.svg';

import styles from './DevLogin.module.scss';
import { DevLoginPageForm } from './DevLoginForm';

export const DevLoginPage = ({ story, pageContext: { globalStories, currentPageId, ...pageContext } }) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.logoContainer}>
        <BoschHeaderLogo className={styles.bmLogo} />
      </div>
      <DevLoginPageForm story={story} />
    </div>
  );
};
