import React, { useState } from 'react';

import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

import { HeadingGroup } from '@components/HeadingGroup/HeadingGroup';

import styles from './DevLogin.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const DevLoginPageForm = ({ story }) => {
  const { content } = story;
  const router = useRouter();
  const [, setCookies] = useCookies();
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async e => {
    e.preventDefault();

    const tomorrow = dayjs()
      .add(1, 'day')
      .toDate();

    setCookies('dev-access', btoa(e.target.password.value), {
      expires: tomorrow,
      path: '/',
      sameSite: 'none',
      secure: true,
    });

    const requestedUrl = router.query.requestedUrl || '/';

    window.location.href = requestedUrl;
    setLoading(true);
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <h2>Loading</h2>
      </div>
    );
  }

  return (
    <div className={styles.headerContainer}>
      <div className={styles.container}>
        <div className={styles.passwordGroup}>
          <div className={styles.label}>
            <HeadingGroup label={content.primaryTitle} />
          </div>
          <form className={styles.formContainer} onSubmit={onSubmitHandler}>
            <input
              name="password"
              placeholder={content.labelPassword}
              className={styles.passwordInput}
              type="password"
            />
            <Button
              as="button"
              variant={ButtonVariant.Icon}
              icon="none"
              theme={ButtonTheme.Secondary}
              type="submit"
              className={styles.button}
            >
              {content.buttonText}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
